.wrapper {
    height: 100vh;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-image: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);
}

.form {
    width: 30%;
    background-color: white;
    padding: 20px 30px;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.formBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lockIcon {
    background-color: lightgray;
    padding: 10px;
    border-radius: 100%;
    margin-bottom: 12px;
}

.loadingDots {
    width: 42px;
    height: 25px;
}
